<template>
  <b-sidebar
    id="report-selector-sidebar"
    ref="report-selector-sidebar"
    bg-variant="white"
    right
    width="400px"
    shadow
    backdrop
    style="z-index: 1000"
    v-model="showSidebar"
  >
    <!-- header -->
    <template #header>
      <div class="d-flex align-items-center justify-content-between w-100">
        <div
          class="ml-2 flip d-flex align-items-center justify-content-center"
          style="height: 40px; width: 40px; border-radius: 50%"
          v-b-toggle.report-selector-sidebar
        >
          <i class="fas fa-chevron-left"></i>
        </div>
      </div>
    </template>

    <!-- body -->
    <div class="px-5">
      <!-- create button -->
      <div class="text-center my-3">
        <button
          class="btn btn-outline-primary w-100"
          @click="create"
          :disabled="!allowCreate"
        >
          + Create new dashboard
        </button>
      </div>

      <!-- dashboard list -->
      <div class="mt-4">
        <h3>Published</h3>
        <div v-for="dashboard in selectDashboards" :key="dashboard.Id">
          <div class="d-flex justify-content-between">
            <button
              class="btn btn-white borderless text-left"
              style="width: 85%"
              v-b-toggle.report-selector-sidebar
              @click="setActiveDashboard(dashboard)"
            >
              {{ dashboard.Name }}
            </button>
            <button
              class="btn btn-white borderless"
              style="width: 15%"
              @click="confirmDelete(dashboard)"
            >
              <i class="far fa-trash-can text-danger"></i>
            </button>
          </div>
          <hr class="m-1" />
        </div>
      </div>

      <!-- draft list -->
      <div class="mt-5" v-if="!isDashboardView">
        <h3>Drafts</h3>
        <div v-for="draft in selectDrafts" :key="draft.Id">
          <div class="d-flex justify-content-between">
            <button
              class="btn btn-white borderless text-left"
              style="width: 85%"
              v-b-toggle.report-selector-sidebar
              @click="setActiveDashboard(draft)"
            >
              {{ draft.Name }}
            </button>
            <!-- <button
              class="btn btn-white borderless"
              style="width: 15%"
              @click="confirmDelete(draft)"
            >
              <i class="far fa-trash-can text-danger"></i>
            </button> -->
          </div>
          <hr class="m-1" />
        </div>
      </div>
    </div>
  </b-sidebar>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const ReportModule = createNamespacedHelpers('report')

export default {
  name: 'ReportSelectorSidebar',
  data: () => ({ showSidebar: false }),
  computed: {
    ...ReportModule.mapGetters([
      'selectDashboards',
      'selectDrafts',
      'selectActiveDashboard',
      'selectBoldBiUrl',
      'selectOBSUrl',
    ]),
    isDashboardView() {
      return this.$route.name === 'report-dashboard'
    },
    allowCreate() {
      return !!Object.keys(this.selectActiveDashboard || {}).length
    },
  },
  methods: {
    ...ReportModule.mapActions([
      'setActiveDashboard',
      'deleteDashboard',
      'createDashboard',
      'deleteDraft',
    ]),
    show() {
      this.showSidebar = true
    },
    async create() {
      this.showSidebar = false
      this.setActiveDashboard({})
      if (this.$route.name !== 'report-dashboard-builder') {
        this.$router.push('builder')
      }
    },
    confirmDelete(dashboard) {
      this.$bvModal
        .msgBoxConfirm(`Are you sure you want to delete "${dashboard.Name}"?`, {
          title: 'Please Confirm!',
          okVariant: 'danger',
          okTitle: 'Delete',
          footerClass: 'p-4',
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.showSidebar = false
            this.deleteDashboard(dashboard)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .b-sidebar {
  border-left: 1.5px solid #d2ddec;
}

.btn-white:hover,
.btn-white:focus,
.btn-white:active,
.btn-white.active {
  background-color: #edf2f9;
}

.flip {
  transition: 0.3s ease-out;
}

.flip:hover {
  transform: rotateZ(180deg);
}

.borderless {
  border: none !important;
}
</style>

<template>
  <div class="card col-12 mt-7">
    <div class="card-body text-center">
      <div class="row">
        <div class="col-12">
          <img
            src="/img/illustrations/rocket.png"
            alt="..."
            class="img-fluid mt--6 mb-4"
            style="max-width: 150px"
          />

          <h2>Ready to take your reports to the next level?</h2>

          <p class="text-muted mb-4">
            {{ actionText }}
          </p>

          <a
            href="mailto:upgrade@ovationup.com?subject=Upgrade%20Request&body=I%20would%20like%20access%20to%20advanced%20reports."
            class="btn btn-lg btn-primary"
          >
            Request Access
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const UserModule = createNamespacedHelpers('user')

export default {
  name: 'ReportOffState',
  computed: {
    ...UserModule.mapGetters(['onlineUser']),
    isAdmin() {
      return this.onlineUser.role === 'admin' 
    },
    actionText() {
      if (this.isAdmin) {
        return 'Get access to our most powerful business intelligence tools and create unique reports for deeper insight.'
      } else {
        return 'Please contact an admin at your company to sign up for access.'
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>

<template>
  <div>
    <div style="margin-bottom: -30px" v-if="!loading">
      <SlideFadeTransition>
        <router-view :key="refreshKey" id="boldbi-container" />
      </SlideFadeTransition>
    </div>

    <!-- loader -->
    <div v-else>
      <FullLoader />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import SlideFadeTransition from '../../Transitions/SlideFadeTransition'
import FullLoader from '@/components/MainContent/FullLoader'

const ReportModule = createNamespacedHelpers('report')

export default {
  name: 'ReportViews',
  components: {
    SlideFadeTransition,
    FullLoader,
  },
  data: () => ({
    loading: true,
  }),
  async mounted() {
    this.injectBoldBiCss()
    await this.fetchDashboardToken()
    await this.fetchReportDashboards()
    this.loading = false
  },
  beforeDestroy() {
    this.removeBoldBiCss()
  },
  computed: {
    ...ReportModule.mapGetters(['selectActiveDashboard']),
    refreshKey() {
      return this.selectActiveDashboard?.Id || 0
    },
  },
  methods: {
    ...ReportModule.mapActions(['fetchReportDashboards', 'fetchDashboardToken']),
    injectBoldBiCss() {
      let styleSheet = document.createElement('link')
      styleSheet.rel = 'stylesheet'
      styleSheet.setAttribute('id', 'boldbiCss')
      styleSheet.href = '/theme/css/boldbi.theme.definition.min.css'
      document.head.appendChild(styleSheet)
    },
    removeBoldBiCss() {
      let stylesheet = document.getElementById('boldbiCss')
      stylesheet.parentNode.removeChild(stylesheet)
    },
  },
}
</script>

<style scoped>
::v-deep #dashboard_embeddedbi .bbi-canvas.bbi-dbrd-control-container {
  border: 1px solid #edf2f9;
}
</style>

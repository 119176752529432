<template>
  <MainContent>
    <div v-if="activeCompany" :key="activeCompany._id">
      <div v-if="advancedReportsEnabled">
        <ReportHeader />
        <ReportViews />
      </div>
      <ReportOffState v-else />
    </div>
  </MainContent>
</template>

<script>
import { trackEvent } from '@/lib/analytics'
import MainContent from '../components/MainContent/MainContent'
import ReportHeader from '../components/Modules/Report/ReportHeader'
import ReportViews from '../components/Modules/Report/ReportViews'
import ReportOffState from '../components/Modules/Report/ReportOffState'
import { createNamespacedHelpers } from 'vuex'

const CompanyModule = createNamespacedHelpers('company')

export default {
  name: 'ReportPage',
  components: {
    MainContent,
    ReportHeader,
    ReportViews,
    ReportOffState,
  },
  data: () => ({}),
  async mounted() {
    trackEvent(this.$intercom, 'ReportPage')
  },
  computed: {
    ...CompanyModule.mapState(['activeCompany']),
    advancedReportsEnabled() {
      return this.activeCompany?.settings?.advancedReportsEnabled
    },
  },
}
</script>

<template>
  <div class="header mb-0" id="report-header">
    <div class="header-body">
      <div class="d-flex align-items-center justify-content-between">
        <!-- title -->
        <div class="ml-2">
          <h1 class="header-title ml-3">Advanced Reports</h1>
        </div>
        <div class="d-flex mr-3">
          <div class="mr-2">
            <button class="btn btn-outline-primary no-outline" @click="navigate('builder')" v-if="isDashboard">
              Editor
            </button>
            <button class="btn btn-outline-primary no-outline" @click="navigate('dashboard')" v-else>
              View Published
            </button>
          </div>
          <button class="btn btn-link text-dark" v-b-toggle.report-selector-sidebar>
            <i class="fas fa-bars fa-xl"></i>
          </button>
        </div>
      </div>
    </div>
    <ReportSelectorSidebar />
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import ReportSelectorSidebar from '@/components/Modals/ReportSelectorSidebar'
import { createNamespacedHelpers } from 'vuex'

const CompanyModule = createNamespacedHelpers('company')

export default {
  name: 'ReportHeader',
  components: {
    Multiselect,
    ReportSelectorSidebar,
  },
  computed: {
    ...CompanyModule.mapGetters(['selectIsPermitted']),
    isDashboard() {
      return this.$route.name === 'report-dashboard'
    },
  },
  methods: {
    navigate(destination) {
      this.$router.push(destination)
    },
  },
}
</script>

<style scoped>
.no-outline {
  outline: none !important;
  box-shadow: none !important;
}
</style>
